// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

.things {
  margin-top: 1rem;

  .things-item {
    border-bottom: 1px solid $border-color;
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;

    .things-title {
      color: $sub-color;
      font-size: 1rem;
      min-width: 12.4rem;
    }

    .things-link {
      font-size: .8rem;
      line-height: 1.2rem;
      min-width: 8rem;
      padding-top: .2rem;
    }

    .things-list {
      font-size: .9rem;
      line-height: 1.4rem;
    }
  }

  ul {
    margin: 0;
  }
}

@media (max-width: $breakpoint) {
  .things {
    .things-item {
      display: block;

      .things-link {
        display: flex;
        padding: .2rem 0;

        a {
          padding-right: .2rem;
        }
      }
    }
  }
}
