// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}

@media (max-width: $breakpoint) {
  html {
    font-size: 14px;
  }
}

body {

  padding-bottom: 50px; /* padding for signup bar */
  background-color: $bg-color;
  color: $main-color;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI", Roboto, Helvetica, Arial, "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  margin: 0;
  text-align: center;
  word-wrap: break-word;

  ::selection {
    background-color: $link-color;
    color: $bg-color;
  }
}

a {
  color: $link-color;
  background-color: #ffffcc;
  text-decoration-style: dotted;

  &:hover {
    text-decoration: underline;
    background-color: #FFFF00;
  }
}

a.nohighlight {
  background-color: transparent;
  &:hover {
    text-decoration: none;
    background-color: transparent;
  }
}

.content {
  text-align: center;
  width: 100%;
}

.content-wrapper {
  margin: 0 auto;
  width: $breakpoint;
}

@media (max-width: $breakpoint) {
  .content-wrapper {
    padding-left: .5rem;
    padding-right: .5rem;
    width: 100%;
  }
}

img {
  border: 0;
  max-width: $breakpoint;
}

@media (max-width: $breakpoint) {
  img {
    max-width: 100%;
  }
}

figure {
  max-width: 100%;
  border: 0;
  display: contents;
  vertical-align: center;
  text-align: center;
  padding: 0;
}

figcaption {
  float: center;
  font-size: smaller;
  color: grey;
  text-indent: 0;
  font-style: italic;
  padding: 0;
  text-align: right;
  padding-left: 50%;
  padding-right: 0%;
}

.space-20 {
  padding:20px;
}

.space-40 {
  padding:40px;
}

.emoji-spacer {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size:40px;
}

.signup-bar {
  background-color: #f8f4ff;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  display: none;
}

.signup-form-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.js-enabled .signup-bar {
  display: flex;
}

.signup-bar .close-btn {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1001;
}