// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

.page {
  text-align: left;

  .page-main {
    .page-title {
      font-family: "Arial",sans-serif;
      font-weight: bold;
      color: white;
      background: black;
      font-size: 1.5rem;
      display: inline;
      background-clip: content-box;
      padding-left: 0;
      margin-left: 0px;
    }
  }

.space-20 {
  padding:20px;
}

.space-40 {
  padding:40px;
}

  h1 {
    font-family: "Arial",sans-serif;
    font-weight: bold;
    color: white;
    background: black;
    font-size: 1.5rem;
    display: inline-block;
    background-clip: content-box;
    padding-left: 0;
    margin-left: 0px;
  }

  h2 {
    font-family: "Arial",sans-serif;
    font-weight: bold;
    color: white;
    background: black;
    font-size: 1.3rem;
    display: inline-block;
    background-clip: content-box;
    margin-left: 0px;
  }
}
