/*
 * Theme of Crisp Blog
 * Copyright(c) David Zhang, 2019
 */

$breakpoint: 40rem;

$main-color:    #212529; // grey 9
$sub-color:     #343a40; // grey 8
$code-bg-color: #495057; // grey 7
$light-color:   #adb5bd; // grey 5
$border-color:  #dee2e6; // grey 3
$bg-color:      #f7fff5; // white

$link-color:    #cc00ff; // blue 5

html {
	scroll-behavior: smooth;
}

.book {
  background-color: #feee9b;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.articles {
  background-color: #b7ecd7;
  padding:1.25em 2.375em;
  margin-bottom: 22px;
}

.chapter {
  background-color: #d9effe;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.working {
  background-color: #fcd4de;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.list1 {
  background-color: #FFB7B2;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.list2 {
  background-color: #FFDAC1;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.list3 {
  background-color: #E2F0CB;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.list4 {
  background-color: #B5EAD7;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

.list5 {
  background-color: #C7CEEA;
  padding: 1.25em 2.375em;
  margin-bottom: 22px;
}

@import "main";
@import "menu";
@import "footer";
@import "page";
@import "article";
@import "code-highlight";
@import "archive";
@import "things";
