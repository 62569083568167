// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

.article {
  margin-bottom: 2rem;
  text-align: left;

  .article-head {
    margin-bottom: 4.2rem;
    margin-top: 4.2rem;
    text-align: left;

    .article-title {
      font-family: "Arial",sans-serif;
      font-weight: bold;
      color: white;
      background: black;
      font-size: 1.5rem;
      display: inline;
      background-clip: content-box;
      padding: 0;
    }

    .article-date {
      color: $light-color;
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  .article-main {
    font-size: 1rem;
    margin-bottom: 3.2rem;
    margin-top: 1.2rem;
  }

  // disqus
  .article-comment {
    iframe {
      text-align: center;
    }
  }
}

.article-back {
  text-align: right;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  display: block;
  font-family: "Arial",sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 1.2rem;
  padding-top: 2rem;
  text-align: left;
}

h2 {
  display: block;
  font-family: "Arial",sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  padding-bottom: 1.3rem;
  padding-top: 4rem;
  text-align: left;
}

h3 {
  color: $sub-color;
  font-size: 1.2rem;
  font-family: "Arial",sans-serif;
  font-weight: bold;
  font-weight: 500;
  padding-bottom: 1.2rem;
  padding-top: 4rem;
  text-align: left;
}

h4 {
  color: $sub-color;
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: .8rem;
  padding-top: .8rem;
  text-align: left;
}

h5 {
  color: $sub-color;
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 0;
  padding-top: .8rem;
  text-align: left;
}

blockquote {
  border-left: .2rem solid $border-color;
  padding-left: 1rem;
}

p {
  hyphens: auto;
  line-height: 1.8rem;
}

sup {
  font-size: .6rem;
  line-height: .6rem;
  vertical-align: auto;
}

hr {
  margin-bottom: 3rem;
  margin-top: 3rem;
  width: 3rem;
}

ul {
  p {
    margin: 0;
  }

  li {
    line-height: 1.8rem;
    margin-left: -1rem;
    list-style-type: circle;

    p {
      line-height: 1.8rem;
      margin: 0;
    }
  }
}

.rtl {
  direction: rtl;
  color: rgba(0, 0, 0, 0.3);
}

table {
  border-collapse: collapse;
  width: 100%;

  tr {
    &:hover {
      background-color: $light-color;
    }

    th {
      background-color: $light-color;
      padding: .2rem .4rem;
    }

    td {
      padding: .2rem .4rem;
    }
  }
}
