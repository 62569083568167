// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

.archive-main {
  margin: 1rem 0;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .archive-item {
    line-height: 1.8rem;
    margin-top: .6rem;
    overflow: auto;

    .archive-date {
      color: $light-color;
      font-size: .8rem;
      margin: 0;
    }
  }
}

.archive-more {
  color: $light-color;

  a {
    color: $light-color;
  }
}
