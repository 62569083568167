// Theme of Crisp Blog
// Copyright(c) David Zhang, 2017

.menu {
  display: flex;
  justify-content: space-between;
  padding-bottom: .4rem;
  padding-top: 1.2rem;
  text-align: left;

  .menu-home {
    font-size: 1.2rem;

    a {
      color: $sub-color;
      text-decoration: none;
    }
  }

  .menu-links {
    display: flex;
    float: right;
    margin-top: 0;
  }

  .menu-item {
    font-size: 1rem;
    padding: .1rem .4rem;
    text-align: left;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    a {
      color: $light-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .menu-active {
    a {
      text-decoration: underline;
    }
  }

  @media (max-width: $breakpoint) {
    display: block;
    padding-top: .4rem;

    .menu-home {
      font-size: 1.8rem;
      padding: .4rem 0;
    }

    .menu-links {
      float: left;
      margin-bottom: 1.8rem;
      width: 100%;
    }
  }
}

.collage {
  position: relative;
  width: 100%;
  max-width: 40rem;
  height: 40rem;
  margin: 3rem auto;
}

.collage a {
  position: absolute;
  display: block;
  cursor: grab;
  z-index: 1;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.collage a img {
  position: absolute;
  max-width: 220px;
  max-height: 220px;
  height: auto;
  width: auto;
  object-fit: cover;
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
  transform: rotate(-2deg);
  opacity: 0.8;
  cursor: pointer
}

.collage a:active {
  cursor: grabbing;
}

.collage a:hover {
  z-index: 10;
  transform: scale(1.05) rotate(0deg);
}

.collage a:hover img {
  transform: scale(1.05) rotate(0deg);
  opacity: 1
}

.collage a:nth-of-type(1)  { top: 5%; left: 20%; transform: translate(-50%, -50%) rotate(-6deg); }
.collage a:nth-of-type(2)  { top: 25%; left: 30%; transform: translate(-50%, -50%) rotate(2deg); }
.collage a:nth-of-type(3)  { top: 2%; left: 70%; transform: translate(-50%, -50%) rotate(12deg); }
.collage a:nth-of-type(4)  { top: 66%; left: 4%; transform: translate(-50%, -50%) rotate(-4deg); }
.collage a:nth-of-type(5)  { top: 42%; left: 6%; transform: translate(-50%, -50%) rotate(-3deg); }
.collage a:nth-of-type(6)  { top: 40%; left: 40%; transform: translate(-50%, -50%) rotate(9deg); }
.collage a:nth-of-type(7)  { top: 45%; left: 63%; transform: translate(-50%, -50%) rotate(-8deg); }
.collage a:nth-of-type(8)  { top: 67%; left: 33%; transform: translate(-50%, -50%) rotate(4deg); }
.collage a:nth-of-type(9)  { top: 30%; left: 64%; transform: translate(-50%, -50%) rotate(0deg); }
.collage a:nth-of-type(10) { top: 7%; left: 5%; transform: translate(-50%, -50%) rotate(10deg); }
.collage a:nth-of-type(11) { top: 40%; left: 56%; transform: translate(-50%, -50%) rotate(10deg); }
.collage a:nth-of-type(12) { top: 65%; left: 75%; transform: translate(-50%, -50%) rotate(3deg); }
